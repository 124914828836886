<template lang="pug">
  tr
    td.shops {{ shopName }}
    td.competitors
      .not-selected(v-if="notSelected")
        | {{ $t("general_settings.competitors_settings.no_competitors_shops_selected") }}
      AppListExpandable.competitor-shop-name(
        v-else
        :items="selectedCompetitorShops"
        valueKey="id"
        titleKey="name"
      )
    td.actions.right
      AppIconButton.action-button(
        v-if="hasEditPermission('competitors_settings')"
        icon="edit"
        @click="editCompetitorSettings"
      )
</template>

<script>
  // mixins
  import withModal from "@/mixins/withModal"
  import withPermissions from "@/mixins/withPermissions"

  // components
  import CompetitorsSettingsForm from "./CompetitorsSettingsForm"

  // misc
  import { isEmpty } from "lodash-es"
  import "vue-slider-component/theme/default.css"

  export default {
    props: {
      shop: {
        type: Object,
        required: true
      }
    },

    mixins: [withModal, withPermissions],

    components: {
      AppListExpandable: () => import("@/components/elements/AppListExpandable"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    computed: {
      shopName() {
        return this.shop.name
      },

      notSelected() {
        return isEmpty(this.selectedCompetitorShops)
      },

      selectedCompetitorShops() {
        return this.shop.competitor_shops || []
      }
    },

    methods: {
      isItemDisabled(item) {
        return this.isMaxSelectedShopsReached && this.competitorShops.includes(item)
      },

      handleDistanceChange(value) {
        this.handleShopChange({ distance: value })
      },

      handleSelectCompetitorShop(competitorShops) {
        this.handleShopChange({ competitor_shops: competitorShops })
      },

      handleSelectCompetitorCompany(competitorCompanies) {
        this.handleShopChange({ competitor_companies: competitorCompanies })
      },

      handleShopChange(shopChanges) {
        this.$emit("shop-change", shopChanges)
      },

      editCompetitorSettings() {
        if (!this.hasEditPermission("competitors_settings")) return

        this.$openModal({
          component: CompetitorsSettingsForm,
          title: this.$t("general_settings.competitors_settings.wizard.title", { shop_name: this.shopName }),
          size: "large",
          props: {
            item: this.shop
          }
        })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  td
    .not-selected
      color: $default-gray

    &.shops
      padding-left: 10px

    &.actions
      text-align: center

      .action-button
        +icon-button($default-purple)

    &.competitors
      padding: 10px 0
</style>
