import StoreItemsModule from "@/config/store/StoreItemsModule"

import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import extractActions from "../shared/actions"

const BASE_URI = "/competitors_shops"

const store = new StoreItemsModule({
  baseURI: BASE_URI,
  presetActions: ["fetch"]
})

store.mergeActions(extractActions({ baseURI: BASE_URI }), withLoading)

export default store
