<template lang="pug">
  .competitors-settings
    BAlert.warning(
      show
      variant="warning"
      :title="$t('general_settings.competitors_settings.max_selected_competitors_shops', { count: maxCompetitorsCount })"
    )
      div {{ $t("general_settings.competitors_settings.max_selected_competitors_shops", { count: maxCompetitorsCount }) }}
      div {{ $t("general_settings.competitors_settings.in_order_to_change_competitors") }}
    .competitors-settings-wrapper
      .competitors-settings-form
        .competitors-settings-form-row
          .competitors-settings-form-row-label
            .competitors-settings-form-row-label-number(style='width: 30px') {{ "1" }}
            .competitors-settings-form-row-label-text {{ $t('general_settings.competitors_settings.wizard.select.distance') }}
          .competitors-settings-form-row-field
            .distance
              VueSlider.distance-slider(
                v-model="distance"
                :tooltip="'none'"
                :min="0"
                :max="10"
                :interval="1"
                @change="handleDistanceChange"
              )
              .distance-label {{ distanceLabel }}
        .competitors-settings-form-row(:class="{ disabled: isOtasSelectionDisabled }")
          .competitors-settings-form-row-label
            .competitors-settings-form-row-label-number {{ "2" }}
            .competitors-settings-form-row-label-text {{ $t('general_settings.competitors_settings.wizard.select.ota') }}
          .competitors-settings-form-row-field
            AppDropdown(
              checkbox
              multiple
              :disabled="isOtasSelectionDisabled"
              allow-empty
              batch-select
              value-key="id"
              title-key="name"
              :value="selectedOtas"
              :items="allowedOtas"
              @select="handleSelectOtaName"
            )
        .competitors-settings-form-row(:class="{ disabled: isCompetitorsCompaniesSelectionDisabled }")
          .competitors-settings-form-row-label
            .competitors-settings-form-row-label-number {{ "3" }}
            .competitors-settings-form-row-label-text {{ $t('general_settings.competitors_settings.wizard.select.company') }}
          .competitors-settings-form-row-field
            AppDropdown.competitor-companies(
              :followReferenceBoundary="false"
              :disabled="isCompetitorsCompaniesSelectionDisabled"
              allow-empty
              batch-select
              checkbox
              multiple
              :loading="competitorCompaniesLoading"
              :value="selectedCompetitorCompanies"
              :items="shopCompetitorCompanies"
              @select="handleSelectCompetitorCompany"
            )
        .competitors-settings-form-row(:class="{ disabled: isCompetitorsShopsSelectionDisabled }")
          .competitors-settings-form-row-label
            .competitors-settings-form-row-label-number {{ "4" }}
            .competitors-settings-form-row-label-text {{ $t('general_settings.competitors_settings.wizard.select.competitors') }}
          .competitors-settings-form-row-field
            AppDropdown.competitors(
              :followReferenceBoundary="false"
              :disabled="isCompetitorsShopsSelectionDisabled"
              allow-empty
              checkbox
              multiple
              searchable
              draggable-selected
              order-direction="keep"
              title-key="name"
              :loading="competitorShopsLoading"
              :value="selectedCompetitorShops"
              :items="shopCompetitorShops"
              :is-item-disabled="isItemDisabled"  
              :max-select-count="maxCompetitorsCount"
              @select="handleSelectCompetitorShop"
            )
            //- it-item-disabled unused prop TODO: investigate and refactore
      .selected-competitors
        .no-selected-items(v-if="isEmpty(selectedCompetitorShops)")
          | {{ $t("general_settings.competitors_settings.no_competitors_shops_selected") }}
        .selected-competitors-item(
          v-else
          v-for="competitorShop in selectedCompetitorShops"
        )
          .selected-competitors-item-text
            span(:title="competitorShop.displayName") {{ competitorShop.displayName }}
          .selected-competitors-item-action
            FaIcon.action-button(
              icon="times"
              @click="handleDeleteSelectedCompetitorShop(competitorShop)"
            )
    .competitors-settings-form-buttons
      .competitors-settings-form-buttons-notification(v-if="isChangesNotificationShown")
        | {{ $t("general_settings.competitors_settings.competitors_shops_changed") }}
      .competitors-settings-form-buttons-clear
        AppButton(
          title="general_settings.clear"
          @click="handleClear"
        )
      .competitors-settings-form-buttons-save
        AppButton(
          title="general_settings.save"
          @click="handleSave"
          :disabled="maxCompetitorsCount === 0"
        )
</template>

<script>
  // store modules
  import competitorsSettingsModule from "@/config/store/competitors/settings"
  import competitorShopsModule from "@/config/store/competitors/shops"
  import competitorCompaniesModule from "@/config/store/competitors/companies"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withAllowedOtaList from "@/mixins/withAllowedOtaList"

  // components
  import VueSlider from "vue-slider-component"

  // misc
  import { appDebounce } from "@/helpers/common"
  import { unionBy, cloneDeep, isEmpty, isEqual } from "lodash-es"
  import "vue-slider-component/theme/default.css"
  import { mapGetters } from "vuex"

  const competitorsSettingsMixin = withStoreModule(competitorsSettingsModule, {
    name: "competitorsSettings",
    actions: { updateShop: "UPDATE_ITEM" }
  })

  const competitorShopsMixin = withStoreModule(competitorShopsModule, {
    name: "competitorShops",
    readers: {
      competitorShops: "items",
      competitorShopsLoading: "loading"
    },
    actions: {
      fetchCompetitorShopsAction: "FETCH_ITEMS"
    },
    mutations: {
      resetCompetitorsShops: "RESET_ITEMS"
    }
  })

  const competitorCompaniesMixin = withStoreModule(competitorCompaniesModule, {
    name: "competitorCompanies",
    readers: {
      competitorCompanies: "items",
      competitorCompaniesLoading: "loading"
    },
    actions: {
      fetchCompetitorCompaniesAction: "FETCH_ITEMS"
    },
    mutations: {
      resetCompetitorsCompanies: "RESET_ITEMS"
    }
  })

  export default {
    props: {
      item: {
        type: Object,
        required: true
      }
    },

    mixins: [competitorsSettingsMixin, competitorShopsMixin, competitorCompaniesMixin, withAllowedOtaList],

    mounted() {
      this.setShop()
      if (this.distanceInMeters) {
        this.fetchCompetitorCompanies()
        this.fetchCompetitorShops()
      }
      this.selectedOtas = this.allowedOtas
    },

    data() {
      return {
        shop: {},
        initialCompetitorShops: [...this.item.competitor_shops],
        distance: 0,
        selectedOtas: []
      }
    },

    computed: {
      ...mapGetters(["currentOrganization"]),

      maxCompetitorsCount() {
        return this.currentOrganization.max_competitors_count
      },

      shopId() {
        return this.shop.id
      },

      distanceInMeters() {
        return this.distance * 1000
      },

      distanceLabel() {
        return `${this.distance} km`
      },

      isCompetitorsCompaniesSelectionDisabled() {
        return this.isOtasSelectionDisabled || this.selectedOtas.length < 1
      },

      isCompetitorsShopsSelectionDisabled() {
        return isEmpty(this.selectedCompetitorCompanies) || this.isCompetitorsCompaniesSelectionDisabled
      },

      isOtasSelectionDisabled() {
        return this.distance < 1
      },

      selectedCompetitorShops() {
        // choosen for current shop
        return this.prepareShops(this.shop.competitor_shops || [])
      },

      transformedCompetitorShops() {
        // from store according to request
        return this.prepareShops(this.competitorShops || [])
      },

      selectedCompetitorCompanies() {
        return this.shop.competitor_companies || []
      },

      isMaxSelectedShopsReached() {
        return this.selectedCompetitorShops.length === this.maxCompetitorsCount
      },

      shopCompetitorCompanies() {
        return unionBy(this.selectedCompetitorCompanies, this.competitorCompanies, "id")
      },

      shopCompetitorShops() {
        return unionBy(this.selectedCompetitorShops, this.transformedCompetitorShops, "id")
      },

      competitorsShopsChanged() {
        return !isEqual(this.initialCompetitorShops, this.shop.competitor_shops)
      },

      isChangesNotificationShown() {
        return !isEmpty(this.initialCompetitorShops) && this.competitorsShopsChanged
      }
    },

    components: {
      VueSlider,
      AppTextExpandable: () => import("@/components/elements/AppTextExpandable"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppButton: () => import("@/components/elements/AppButton"),
      AppSaveButton: () => import("@/components/elements/AppButton/Save")
    },

    methods: {
      isEmpty,

      debouncedFetchCompetitorCompanies: appDebounce(function() {
        this.fetchCompetitorCompanies()
      }),

      debouncedFetchCompetitorShops: appDebounce(function() {
        this.fetchCompetitorShops()
      }),

      setShop() {
        this.shop = cloneDeep(this.item)
        this.distance = this.shop.distance
      },

      handleDeleteSelectedCompetitorShop(shopToRemove) {
        this.shop.competitor_shops = this.shop.competitor_shops.filter(({ id }) => id !== shopToRemove.id)
      },

      isItemDisabled(item) {
        return this.isMaxSelectedShopsReached && this.competitorShops.includes(item)
      },

      handleDistanceChange() {
        this.debouncedFetchCompetitorCompanies()
        if (this.selectedCompetitorCompanies) {
          this.debouncedFetchCompetitorShops()
        }
      },

      handleSelectCompetitorShop(competitorShops) {
        this.shop.competitor_shops = competitorShops.map((item, index) => {
          return { ...item, shop_order: index }
        })
      },

      handleSelectCompetitorCompany(competitorCompanies) {
        this.shop.competitor_companies = competitorCompanies
        this.fetchCompetitorShops()
      },

      fetchCompetitorCompanies() {
        this.fetchCompetitorCompaniesAction({
          shop_id: this.shopId,
          distance_in_meters: this.distanceInMeters
        })
      },

      fetchCompetitorShops() {
        this.fetchCompetitorShopsAction({
          shop_id: this.shopId,
          competitors_company_ids: this.selectedCompetitorCompanies.map(({ id }) => id),
          distance_in_meters: this.distanceInMeters
        })
      },

      handleClear() {
        this.shop.competitor_shops = []
        this.shop.competitor_companies = []
        this.selectedOtas = [...this.allowedOtas]
        this.resetCompetitorsShops()
        this.resetCompetitorsCompanies()
        this.distance = 0
      },

      handleSave() {
        let competitorShopsCopy = [...this.shop.competitor_shops]
        competitorShopsCopy = competitorShopsCopy.map(shop => {
          let shopWithoutDisplayName = { ...shop }
          delete shopWithoutDisplayName.displayName
          return shopWithoutDisplayName
        })
        this.updateShop({
          ...this.shop,
          competitor_shops: competitorShopsCopy
        })
        this.$emit("close")
      },

      handleSelectOtaName(selectedOtaName) {
        this.selectedOtas = selectedOtaName
        this.shop.competitor_shops = this.filteredShopsByOta(this.shop.competitor_shops)
      },

      orderShops(shops) {
        return shops.sort((a, b) => a.shop_order - b.shop_order)
      },

      formNameWithOtaForShops(shops) {
        return shops.map(shop => {
          return {
            ...shop,
            displayName: `(${this.$t(`ota.${shop.ota_name}`)})\u00A0\u00A0${shop.name}`
          }
        })
      },

      filteredShopsByOta(shops) {
        return shops.filter(shop => {
          return this.selectedOtas.some(ota => ota?.rawName === shop.ota_name)
        })
      },

      prepareShops(shops) {
        let preparedShops = this.filteredShopsByOta(shops)
        preparedShops = this.orderShops(preparedShops)
        preparedShops = this.formNameWithOtaForShops(preparedShops)
        return preparedShops
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .selected-competitors
    padding: 0 10px
    height: 350px
    overflow-x: hidden
    overflow-y: auto

    .no-selected-items
      width: 360px
      padding-top: 30px
      text-align: center
      color: $default-gray

    &-item
      align-items: center
      display: flex
      width: 360px
      justify-content: space-between
      margin-bottom: 20px
      border: 1px solid $default-purple
      border-radius: 10px
      background: linear-gradient(90deg, $default-white 92%, $default-purple-light 8%)

      &-text
        width: 360px
        padding: 5px 10px
        overflow: hidden
        position: relative
        display: inline-block
        text-decoration: none
        text-overflow: ellipsis
        white-space: nowrap

      &-action
        padding: 6px 20px 6px 12px
        border-top-right-radius: 10px
        border-bottom-right-radius: 10px
        width: 5%
        color: $default-purple

        .action-button
          cursor: pointer

  .competitors-settings
    width: 1000px

    .warning
      text-align: center

    &-wrapper
      width: 1010px
      display: flex
      justify-content: space-between

    &-form
      &-buttons
        background: $default-white
        bottom: -$modal-padding
        display: flex
        justify-content: flex-end
        padding-top: 35px
        z-index: 6
        position: sticky

        &-notification
          margin-right: 10px
          padding-top: 7px
          color: $default-gray
          font-size: 0.8rem

        ::v-deep
          .app-button
            background: $default-purple
            color: $default-white
            font-size: 0.8rem
            text-transform: uppercase

        &-clear
          margin-right: 5px

          ::v-deep
            .app-button
              background: $default-white
              color: $default-purple
              border: 1px solid $default-purple

      &-row
        align-items: center
        display: flex
        margin: 0 0 20px 0
        width: 600px
        border: 1px solid $default-purple
        border-radius: 10px
        background: linear-gradient(270deg, $default-white 75%, $default-purple-light 25%)

        &.disabled
          cursor: not-allowed
          opacity: 0.65

        &-label
          align-items: center
          display: flex
          font-size: 0.8rem
          border-top-left-radius: 10px
          border-bottom-left-radius: 10px
          padding: 21px 10px
          width: 30%
          color: $default-purple

          &-number
            border: 2px solid $default-purple
            border-radius: 50%
            text-align: center
            width: 28px
            line-height: 8px
            padding: 8px

          &-text
            margin-left: 17px

        &-field
          padding: 12px
          width: 90%

      .distance
        padding: 6px
        display: flex
        align-items: center
        justify-content: space-between

        &-label
          margin-left: 15px
          width: 25%
          color: $default-gray

        &-slider
         width: 180% !important

        ::v-deep
          .vue-slider-process
            background-color: $default-purple
          .vue-slider-dot-handle-focus
            box-shadow: 0 0 1px 2px rgba(89, 101, 231, 0.5)

      ::v-deep .app-select-label
        max-width: 240px
</style>
